<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="600"
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h2>{{title}}</h2>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-2">
        <!-- LIST VEHICLE -->
        <v-card-text class="mt-4 pb-0">
          <v-row
            justify="space-between"
          >
            <v-col cols="12" sm="4">
              <h3>{{$_strings.order.VEHICLE}}</h3>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                placeholder="Cari Nomor Kendaraan"
                outlined
                dense
                class="caption"
                hide-details
                prepend-inner-icon="mdi-magnify"
                v-on:keyup.enter="getVehicle($event)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="isLoadingListVehicle">
            <v-progress-circular
              :size="50"
              class="ma-10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row v-else-if="isErrorGetListVehicle" class="pa-8" justify="center" >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="getVehicle"
                  x-large
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon large>
                    mdi-reload
                  </v-icon>
                </v-btn>
              </template>
              <span>Reload Halaman</span>
            </v-tooltip>
          </v-row>
          <v-row class="pa-8" justify="center" v-else-if="listVehicle.length === 0">
            <h2>{{$_strings.order.NO_DATA_DRIVER}}</h2>
          </v-row>
          <v-row v-else>
            <v-col v-for="(vehicle, index) in listVehicle" :key="index" class="pt-0 pb-0" sm="4">
              <v-card
                :class="form.transportId === vehicle.id ? 'border-active' : 'border-inactive'"
                class="mx-auto my-12"
                max-width="374"
                hover
                :ref="'vehicleSelected'+vehicle.id"
                @click="form.transportId = vehicle.id"
              >
                <div
                  v-if="!vehicle.imageUrl"
                  style="height:120px;"
                  class="d-flex justify-center align-center"
                >
                  <v-icon
                    x-large
                  >
                    mdi-truck-outline
                  </v-icon>
                </div>
                <v-img
                  v-else
                  height="120"
                  width="374"
                  contain
                  :src="vehicle.imageUrl"
                  aspect-ratio="1"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="d-flex flex-column align-start">
                  <span class="caption">{{vehicle.licensePlate}}</span>
                  <v-btn aria-readonly v-if="isExpired(vehicle.kirExpiredDate)" class="red caption white--text mt-2" x-small>KIR Expired</v-btn>
                  <v-btn aria-readonly v-if="isExpired(vehicle.stnkExpiredDate)" class="red caption white--text mt-2" x-small>STNK Expired</v-btn>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="listVehicle.length > 0">
            <v-col sm="5">
              <span>{{$_strings.order.LIST_VEHICLE}}</span>
            </v-col>
            <v-col class="d-flex align-center justify-end" sm="7">
              <v-pagination
                v-model="paginationListVehicle.page"
                :length="paginationListVehicle.totalPage"
                circle
                @input="handlePaginationListVehicle"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- LIST DRIVERS -->
        <v-divider class="mt-4 ml-2 mr-2"></v-divider>
        <v-card-text class="mt-4 pb-0">
          <v-row
            justify="space-between"
          >
            <v-col cols="12" sm="4">
              <h3>{{$_strings.order.DRIVER}}</h3>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                placeholder="Cari Nama Sopir"
                outlined
                dense
                :value="paginationListDrivers.name"
                class="caption"
                hide-details
                prepend-inner-icon="mdi-magnify"
                v-on:keyup.enter="getDrivers($event)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="isLoadingListDriver">
            <v-progress-circular
              :size="50"
              class="ma-10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row v-else-if="isErrorGetListDriver" class="pa-8" justify="center" >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="getDrivers"
                  x-large
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon large>
                    mdi-reload
                  </v-icon>
                </v-btn>
              </template>
              <span>Reload Halaman</span>
            </v-tooltip>
          </v-row>
          <v-row class="pa-8" justify="center" v-else-if="listDrivers.length === 0">
            <h2>{{$_strings.order.NO_DATA_DRIVER}}</h2>
          </v-row>
          <v-row v-else>
            <v-col v-for="(driver, index) in listDrivers" :key="index" class="pt-0 pb-0" sm="4">
              <v-card
                :class="form.companyDriverId === driver.id ? 'border-active' : 'border-inactive'"
                class="mx-auto my-12"
                max-width="374"
                hover
                :ref="'vehicleSelected'+driver.id"
                @click="form.companyDriverId = driver.id"
              >
                <v-img
                  v-if="!driver.imageUrl"
                  height="120"
                  width="374"
                  contain
                  src="@/assets/images/userProfile.png"
                  aspect-ratio="1"
                ></v-img>
                <v-img
                  v-else
                  height="120"
                  width="374"
                  contain
                  :src="driver.imageUrl"
                  aspect-ratio="1"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title :class="!$vuetify.breakpoint.xs ? 'v-card-title-custom' : ''" class="d-flex flex-column align-start">
                  <span class="caption">{{driver.platNo}}</span>
                  <span class="caption font-weight-bold">{{driver.phoneNo}}</span>
                  <span class="body-2 font-weight-medium">{{driver.name}}</span>
                </v-card-title>
                <v-card-text>
                  <v-btn aria-readonly v-if="driver.isExpired" class="red caption white--text" x-small>Expired</v-btn>
                  <v-btn aria-readonly v-else-if="driver.isSuspended" class="red caption white--text" x-small>Suspend</v-btn>
                  <v-btn v-else aria-readonly class="primary caption" x-small>Rekomendasi</v-btn>
                  <div class="d-flex justify-end">
                    <v-rating
                      length="1"
                      :value="1"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="14"
                    ></v-rating>
                    <div class="grey--text">
                      {{toFixedScore(driver.score)}}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="listDrivers.length > 0">
            <v-col sm="5">
              <span>{{$_strings.order.LIST_DRIVERS}}</span>
            </v-col>
            <v-col class="d-flex align-center justify-end" sm="7">
              <v-pagination
                v-model="paginationListDrivers.page"
                :length="paginationListDrivers.totalPage"
                circle
                @input="handlePaginationListDriver"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!(form.transportId && form.companyDriverId) || isLoading"
          @click="saveDriver"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';

const { CancelToken } = axios;
let source = CancelToken.source();

const date = new Date();
export default {
  props: {
    title: {
      type: String,
      default: 'Pilih Kendaraan dan Sopir',
    },
    reFetchOrder: {
      type: Boolean,
      default: () => false,
    },
    service: {
      type: Function,
    },
    afterAction: {
      type: Function,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      isLoadingListVehicle: false,
      isLoadingListDriver: false,
      isErrorGetListVehicle: false,
      isErrorGetListDriver: false,
      listDrivers: [],
      listVehicle: [],
      shipment: {},
      paginationListDrivers: {
        page: 1,
        size: 3,
        totalPage: 0,
        totalData: 0,
        name: '',
      },
      paginationListVehicle: {
        page: 1,
        size: 3,
        totalPage: 0,
        totalData: 0,
        name: '',
      },
      transportTypeId: null,
      form: {
        statusDatetimeTz: date.toString().split('+')[1].substr(1, 1),
        shipmentCargoId: null,
        companyDriverId: null,
        transportId: null,
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        source = CancelToken.source();
        this.getListData();
      }
      if (!newVal) {
        this.form = {
          statusDatetimeTz: date.toString().split('+')[1].substr(1, 1),
          shipmentCargoId: null,
          companyDriverId: null,
          transportId: null,
        };
        source.cancel('CANCELED_BY_THE_USER');
      }
    },
    'form.transportId': function transportId() {
      const findVehicle = this.listVehicle.find((vehicle) => vehicle.id === this.form.transportId);
      if (findVehicle) {
        const findDriver = this.listDrivers.find((driver) => driver.id === findVehicle.companyDriverId);
        if (!findDriver) {
          this.form.companyDriverId = null;
          const event = {
            target: {
              value: findVehicle.driverName,
            },
          };
          this.paginationListDrivers.name = findVehicle.driverName;
          this.getDrivers(event);
          return;
        }
        this.form.companyDriverId = findDriver.id;
      }
    },
  },
  methods: {
    handlePaginationListDriver(val) {
      this.paginationListDrivers.page = val;
      this.getDrivers();
    },
    handlePaginationListVehicle(val) {
      this.paginationListVehicle.page = val;
      this.getVehicle();
    },
    getListData() {
      this.getDrivers();
      this.getVehicle();
    },
    toFixedScore(score) {
      const includeDot = String(score).includes('.');
      if (includeDot) {
        return score.toFixed(2);
      }
      return score;
    },
    setDriver() {
      const findVehicle = this.listVehicle.find((vehicle) => vehicle.id === this.form.transportId);
      if (findVehicle) {
        const findDriver = this.listDrivers.find((driver) => driver.id === findVehicle.companyDriverId);
        if (!findDriver) {
          this.form.companyDriverId = null;
          return;
        }
        this.form.companyDriverId = findDriver.id;
      }
    },
    async getDrivers(event = false) {
      if (event) {
        this.paginationListDrivers.name = event.target.value;
        this.paginationListDrivers.page = 1;
      }
      const { page, name } = this.paginationListDrivers;
      const { shipmentCargoId } = this.form;
      const filters = {
        page: page - 1,
        size: 3,
        sort: 'createdAt,DESC',
        cargoId: shipmentCargoId,
        isActive: true,
        name,
      };
      this.form.companyDriverId = null;
      try {
        this.isLoadingListDriver = true;
        this.isErrorGetListDriver = false;
        const listDrivers = await this.$_services.order.getDrivers(filters);
        if (listDrivers.data.contents.length === 0) {
          this.$dialog.notify.error('Tidak menemukan driver');
        }
        this.listDrivers = listDrivers.data.contents;
        this.paginationListDrivers.totalPage = listDrivers.data.totalPage;
        this.paginationListDrivers.totalData = listDrivers.data.totalData;
        this.setDriver();
      } catch {
        this.isErrorGetListDriver = true;
      } finally {
        this.isLoadingListDriver = false;
      }
    },
    async getVehicle(event = false) {
      if (event) {
        this.paginationListVehicle.name = event.target.value;
        this.paginationListVehicle.page = 1;
      }
      const { page, name } = this.paginationListVehicle;
      const { shipmentCargoId } = this.form;
      const filters = {
        page: page - 1,
        size: 3,
        sort: 'createdAt,DESC',
        transportTypesId: this.transportTypeId,
        cargoId: shipmentCargoId,
        isActive: true,
      };
      if (name) filters.licensePlate = name;
      this.form.transportId = null;
      try {
        this.isLoadingListVehicle = true;
        this.isErrorGetListVehicle = false;
        const listVehicle = await this.$_services.order.getVehicle(filters);
        if (listVehicle.data.contents.length === 0) {
          this.$dialog.notify.error('Tidak menemukan kendaraan');
        }
        this.listVehicle = listVehicle.data.contents;
        this.paginationListVehicle.totalPage = listVehicle.data.totalPage;
        this.paginationListVehicle.totalData = listVehicle.data.totalData;
        const { companyTransportNo } = this.shipment;
        const findTransporter = this.listVehicle.find((transport) => transport.licensePlate === companyTransportNo);
        if (findTransporter) {
          this.form.transportId = findTransporter.id;
          this.setDriver();
        }
      } catch {
        this.isErrorGetListVehicle = true;
      } finally {
        this.isLoadingListVehicle = false;
      }
    },
    async saveDriver() {
      try {
        this.isLoading = true;
        await this.service()(this.form, source);
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
        this.afterAction();
      } finally {
        this.isLoading = false;
      }
    },
    isExpired(ExpiredDate) {
      const dateNow = dayjs().format();
      const expiredDate = dayjs(ExpiredDate);
      const diffExpired = expiredDate.diff(dateNow, 'day');
      if (diffExpired <= 0) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
  .border-active {
    border: 1px solid;
    border-color: blue;
  }
  .border-inactive {
    border: 1px solid;
    border-color: rgb(255, 255, 255);
  }
  .v-card-title-custom {
    span {
      display: inline-block;
      width: 120px;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
    }
  }
</style>
